.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background: rgba(231, 231, 231, 0.212);
  position: fixed;

  .loader {
    position: relative;
    width: 100px;
    height: 100px;

    .loader-border {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 4px solid rgba(0, 0, 0, 0.1);
      border-top: 4px solid var(--color-primary);
      border-radius: 50%;
      animation: spin 1s linear infinite;
    }

    .loader-logo {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 50px; // Adjust the size as needed
      height: 50px; // Adjust the size as needed
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}