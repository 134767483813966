.table-pagination {
  .css-1gak8h1-MuiToolbar-root-MuiTablePagination-toolbar,
  .css-1gpuzre-MuiSvgIcon-root-MuiSelect-icon {
    color: whitesmoke !important;
  }

  .css-cdtzx3-MuiButtonBase-root-MuiIconButton-root {
    color: rgba(245, 245, 245, 0.681) !important;
  }
}

.css-7mt0f-MuiTablePagination-root {
  overflow: hidden !important;
}

.table-component-container {
  padding: 16px;
  background-color: var(--color-dark-gray);
  // border-radius: 8px;
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .profile-img {
    height: 30px;
    width: 30px;
    border: 2px solid var(--color-primary);
    border-radius: 50%;
    margin: auto;
  }
}
