// .request {
//   height: 90vh;
//   .title {
//     font-size: 34px;
//     font-weight: 600;
//   }
//   .contect {
//     background-color: var(--color-light-gray);
//     display: flex;
//     flex-grow: 1;
//   }
// }

.request {
  .title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: var(--color-light-gray);
  }

  .container {
    padding: 16px;
    background-color: var(--color-dark-gray);
    border-radius: 8px;

    .contect {
      background-color: var(--color-dark-gray);
    }
  }

  .card-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit,
        minmax(250px, 1fr)); // Responsive columns
    gap: 16px; // Space between cards
  }

  .card {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;

    &:hover {
      transform: translateY(-4px);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    }

    .card-title {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 8px;
    }

    .card-description {
      font-size: 14px;
      color: #757575;
    }
  }
}

.request-detatls-table {
  .css-1dc80h3-MuiTableCell-root {
    padding: 8px !important;
  }
}