.teacher {
    .documents-list {
        .doc-image {
            max-width: 200px;
            max-height: 40vh;
        }
    }
}

.teacher-details {
    .highest-qualification-img {
        max-height: 200px;
        max-width: 200px;
    }
}