$dark: #171717;
$main: #fcd307;
$danger: #ff0000;
$light: #f6f8fc;
$medium: #d3d3d3;

// src/styles/global.scss
:root {
  --color-white: #ffffff;
  --primary-dark: #171717;
  --color-primary: #fcd307;
  --color-dark-gray: #464646;
  --color-gray: #636363;
  --color-light-gray: #dfdfdf;
  --light: #f6f8fc;
  --medium: #d3d3d3;
  --danger: #ff0000
}

.bg-dark {
  background-color: #171717;
}

.layout {
  height: 100vh;

  .main-content {
    background-color: var(--primary-dark);
  }
}

.small-input {
  .MuiInputBase-root {
    height: 36px; // Adjust to your preferred height
    font-size: 14px; // Adjust font size for a compact look
    padding: 4px 8px; // Adjust padding for consistent look
  }

  .MuiInputBase-input {
    padding: 4px 8px; // Adjust internal input padding
  }

  .MuiOutlinedInput-root {
    border-radius: 4px; // Optional: Rounded corners for input fields
    border: 1px solid $medium; // Border color based on theme color
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: $medium; // Default border color
  }

  &:focus-within {
    .MuiOutlinedInput-notchedOutline {
      border-color: $dark; // Change border color on focus
    }
  }
}


.table-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;

  .title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: var(--color-light-gray);
  }

  .container {
    padding: 16px;
    padding-bottom: 0px;
    background-color: var(--color-dark-gray);
    border-radius: 8px;
    // flex-grow: 1;
    // height: fit-content;


  }
}

/* Global Scrollbar Styles */
::-webkit-scrollbar {
  width: 8px;
  /* Width of the scrollbar */
  height: 8px;
  /* Height for horizontal scrollbars */
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(135deg, var(--color-primary), var(--color-gray));
  /* Gradient using primary and gray colors */
  border-radius: 10px;
  /* Rounded corners for the scrollbar thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(135deg, var(--primary-dark), var(--color-dark-gray));
  /* Darker gradient on hover */
}

::-webkit-scrollbar-track {
  background: var(--color-light-gray);
  /* Light color for the scrollbar track */
  border-radius: 10px;
  /* Rounded corners for the scrollbar track */
}

::-webkit-scrollbar-track:hover {
  background: var(--color-light-gray);
  /* Slightly darker track on hover */
}

/* For Firefox (Custom Scrollbar) */
.custom-scrollbar {
  scrollbar-width: thin;
  /* Makes the scrollbar thinner */
  scrollbar-color: var(--color-primary) var(--light);
  /* Thumb and track colors for Firefox */
}


input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}