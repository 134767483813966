.rsw-ce {
    background: #fff;
    max-height: 368px;
    overflow-y: auto;
}

.rsw-toolbar {
    position: sticky;
    top: 0;
}

.page-content {
    text-align: left;
    .content {
        background: #fff;
        padding: 16px;
        border-radius: 16px;
        margin-top: 40px;
    }

    .color-picker-container {
        position: fixed;
        left: 50%;
        top: 15%;
        // width: 100vw;
        // height: 100vh;
        // z-index: 999;

        // .color-picker {
        //     left: 50%;
        //     top: 15%;
        //     position: absolute;
        //     z-index: 1000;
        // }
    }
}
