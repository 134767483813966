// src/pages/login/Login.scss


.login-container {
  background-color: var(--primary-dark);
  width: 100%;
  height: 100vh;
  background: url("../../../public/login-bg.svg") no-repeat center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;

  .login-card {
    background: rgba(133, 133, 133, 0.25);
    max-width: calc(100% - 32px);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(3px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    width: 400px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    .logo {
      margin-bottom: 16px;
    }

    h5 {
      color: var(--light);
      margin-bottom: 20px;
      font-weight: bold;
    }

    .alert {
      margin-bottom: 15px;
      color: var(--danger);
    }

    .form-field {
      width: 100%;
      margin-bottom: 16px;

      .MuiOutlinedInput-root {
        background-color: white;
      }
    }

    .login-btn {
      width: 100%;
      background-color: var(--color-primary);
      color: white;
      padding: 10px;
      font-size: 16px;
      font-weight: bold;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
      }

      &:hover:not(:disabled) {
        background-color: var(--color-primary);
        opacity: 0.5;
      }
    }
  }
}

.input-field:-webkit-autofill,
.input-field:-webkit-autofill:hover,
.input-field:-webkit-autofill:focus,
.input-field:-webkit-autofill:active {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  -webkit-text-fill-color: white !important;
  transition: background-color 5000s ease-in-out 0s !important;
}