/* src/styles/global.scss or in NotFound.module.scss */
button {
    background-color: #1976d2;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background-color: #1565c0;
    }
}